import { axios } from './index';

export default {
	/**
	 * Get all educational levels.
	 *
	 * @param {Object} params
	 * @return {Object}
	 */
    all(params) {
        return axios.get('educational_levels', {
            params: params
        })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
	 * Store a new educational level.
	 *
	 * @param {Object} data
	 * @return {Object}
	 */
    store(data) {
        return axios.post('educational_levels', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
     * Get the specified educational level
     *
	 * @param {Number} id
	 * @return {Object}
     */
    show(id) {
        return axios.get(`educational_levels/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
	 * Update the specified educational level.
	 *
	 * @param {Number} id
	 * @param {Object} data
	 * @return {Object}
	 */
    update(id, data) {
        return axios.put(`educational_levels/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
     * Delete the specified educational level
     *
	 * @param {Number} id
	 * @return {Object}
     */
    delete(id) {
        return axios.delete(`educational_levels/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    }
}