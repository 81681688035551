import { axios } from './index';

export default {
    /**
     * Get all electoral areas.
     *
     * @param {Object} params
     * @return {Object}
     */
    all(params) {
        return axios.get('electoral_areas', {
            params: params
        })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Store a new electoral area
     *
     * @param {Object} data
     * @return {Object}
     */
    store(data) {
        return axios.post('electoral_areas', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Get the specified electoral area.
     *
     * @param {Number} id
     * @return {Object}
     */
    show(id) {
        return axios.get(`electoral_areas/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Update the specified electoral area.
     *
     * @param {Number} id
     * @param {Object} data
     * @return {Object}
     */
    update(id, data) {
        return axios.put(`electoral_areas/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Delete the specified electoral area.
     *
     * @param {Number} id
     * @return {Object}
     */
    delete(id) {
        return axios.delete(`electoral_areas/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    }
}