import ExecutivesService from '../../services/executives';
import { SET_EXECUTIVES } from '../mutation-types';

const state = {
	executives: [],
	pageDetails: {
		total: 0,
		from: 0,
		to: 0,
		perPage: 0,
		currentPage: 0,
		lastPage: 0
	}
};

const getters = {
	/**
	 * Get all executives from the state.
	 *
	 * @param  {Object} state
	 * @return {String}
	 */
    getAll(state) {
        return state.executives;
	},

	/**
	 * Get executives pagination details
	 *
	 * @param {Object} state
	 */
	getPageDetails(state) {
		return state.pageDetails;
	}
};

const mutations = {
    [SET_EXECUTIVES](state, payload) {
		if(payload.data) {
			state.executives = payload.data;

			state.pageDetails = {
				total: payload.total,
				from: payload.from,
				to: payload.to,
				currentPage: payload.current_page,
				lastPage: payload.last_page,
				perPage: payload.per_page
			};
		} else {
			state.executives = payload;
		}
    },
};

const actions = {
	/**
	 * Get all executives
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} query
	 * @return {Object}
	 */
    all({ commit }, query) {
        return ExecutivesService.all(query)
            .then((response) => {
                commit(SET_EXECUTIVES, response);
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Store a new executive
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} data
	 * @return {Object}
	 */
    store({ commit, actions }, { data }) {
        return ExecutivesService.store(data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Get the specified executive.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id
	 * @return {Object}
	 */
    show({ commit, actions }, id) {
        return ExecutivesService.show(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Update the specified executive.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} executive
	 * @return {Object}
	 */
    update({ commit, actions }, {id, data}) {
        return ExecutivesService.update(id, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Delete the specified executive.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id
	 * @return {Object}
	 */
    delete({ commit, actions }, id) {
        return ExecutivesService.delete(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
};

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    getters,
    mutations
}