import { axios } from './index';

export default {
	/**
	 * Get all constituencies.
	 *
	 * @param {Object} params
	 * @return {Object}
	 */
    all(params) {
        return axios.get('constituencies', {
                params: params
            })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
	 * Store a new constituency
	 *
	 * @param {Object} data
	 * @return {Object}
	 */
    store(data) {
        return axios.post('constituencies', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
     * Get the specified constituency.
     *
	 * @param {Number} id
	 * @return {Object}
     */
    show(id) {
        return axios.get(`constituencies/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
	 * Update the specified constituency
	 *
	 * @param {Number} id
	 * @param {Object} data
	 * @return {Object}
	 */
    update(id, data) {
        return axios.put(`constituencies/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
     * Delete the specified constituency.
     *
	 * @param {Number} id
	 * @return {Object}
     */
    delete(id) {
        return axios.delete(`constituencies/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    }
}