import { axios } from './index';

export default {
	/**
	 * Get all branches.
	 *
	 * @param {Object} params
	 * @return {Object}
	 */
    all(params) {
        return axios.get('branches', {
                params: params
            })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
	 * Store a new branch
	 *
	 * @param {Object} data
	 * @return {Object}
	 */
    store(data) {
        return axios.post('branches', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
     * Get the specified branch.
     *
	 * @param {Number} id
	 * @return {Object}
     */
    show(id) {
        return axios.get(`branches/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
	 * Update the specified
	 *
	 * @param {Number} id
	 * @param {Object} data
	 * @return {Object}
	 */
    update(id, data) {
        return axios.put(`branches/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
     * Delete the specified field group.
     *
	 * @param {Number} id
	 * @return {Object}
     */
    delete(id) {
        return axios.delete(`branches/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    }
}