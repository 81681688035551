import ConstituenciesService from '../../services/constituencies';
import { SET_CONSTITUENCIES } from '../mutation-types';

const state = {
	constituencies: [],
	pageDetails: {
		total: 0,
		from: 0,
		to: 0,
		perPage: 0,
		currentPage: 0,
		lastPage: 0
	}
};

const getters = {
	/**
	 * Get all constituencies from the state.
	 *
	 * @param  {Object} state
	 * @return {String}
	 */
    getAll(state) {
        return state.constituencies;
	},

	/**
	 * Get executives pagination details
	 *
	 * @param {Object} state
	 */
	getPageDetails(state) {
		return state.pageDetails;
	}
};

const mutations = {
    [SET_CONSTITUENCIES](state, payload) {
		if (payload.data) {
			state.constituencies = payload.data;

			state.pageDetails = {
				total: payload.total,
				from: payload.from,
				to: payload.to,
				currentPage: payload.current_page,
				lastPage: payload.last_page,
				perPage: payload.per_page
			};
		} else {
			state.constituencies = payload;
		}
    },
};

const actions = {
	/**
	 * Get constituencies with query params applied.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} query
	 * @return {Object}
	 */
    all({ commit }, query) {
        return ConstituenciesService.all(query)
            .then((response) => {
                commit(SET_CONSTITUENCIES, response);
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Store a new constituency.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} constituency
	 * @return {Object}
	 */
    store({ commit, actions }, {data}) {
        return ConstituenciesService.store(data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Get the specified constituency.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id Id of constituency
	 * @return {Object}
	 */
    show({ commit, actions }, id) {
		return ConsituenciesService.update(id, data)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
    },

	/**
	 * Update the specified constituency.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} constituency
	 * @return {Object}
	 */
    update({ commit, actions }, {id, data}) {
        return ConstituenciesService.update(id, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Delete the specified constituency.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id Id of constituency
	 * @return {Object}
	 */
    delete({ commit, actions }, id) {
        return ConstituenciesService.delete(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
};

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    getters,
    mutations
}