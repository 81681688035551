// General
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SHOW_APP_LOADER = 'SHOW_APP_LOADER';
export const HIDE_APP_LOADER = 'HIDE_APP_LOADER';

// Auth Mutation Types
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const LOGOUT = 'LOGOUT';

// Elections
export const SET_ELECTIONS = 'SET_ELECTIONS';
export const SET_CANDIDATES = 'SET_CANDIDATES';
export const SET_DATA_COLLECTORS = 'SET_DATA_COLLECTORS';
export const SET_ELECTION_RESULTS = 'SET_ELECTION_RESULTS';

// Election Reports
export const SET_RESULT_SUMMARY_DATA = 'SET_RESULT_SUMMARY_DATA';

// Executives
export const SET_EXECUTIVES = 'SET_EXECUTIVES';

// Executive types
export const SET_EXECUTIVE_TYPES = 'SET_EXECUTIVE_TYPES';

// Educational levels
export const SET_EDUCATIONAL_LEVELS = 'SET_EDUCATIONAL_LEVELS';

// Regions
export const SET_REGIONS = 'SET_REGIONS';

// Set electoral areas
export const SET_ELECTORAL_AREAS = 'SET_ELECTORAL_AREAS';

// Branches
export const SET_BRANCHES = 'SET_BRANCHES';

// Constituencies
export const SET_CONSTITUENCIES = 'SET_CONSTITUENCIES';

// Parties
export const SET_PARTIES = 'SET_PARTIES';

// Users
export const SET_USERS = 'SET_USERS';

// Hierarchies
export const SET_HIERARCHIES = 'SET_HIERARCHIES';

// Roles
export const SET_ROLES = 'SET_ROLES';

// Rejected ballots
export const SET_POLLING_SUMMARIES = 'SET_POLLING_SUMMARIES';