import ElectionResultsService from '../../services/election_results';
import { SET_ELECTION_RESULTS } from '../mutation-types';

const state = {
	electionResults: [],
	pageDetails: {
		total: 0,
		from: 0,
		to: 0,
		perPage: 0,
		currentPage: 0,
		lastPage: 0
	}
};

const getters = {
	/**
	 * Get all election results from the state.
	 *
	 * @param  {Object} state
	 * @return {String}
	 */
	getAll(state) {
		return state.electionResults;
	},

	/**
	 * Page details.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	getPageDetails(state) {
		return state.pageDetails;
	}
};

const mutations = {
	[SET_ELECTION_RESULTS](state, payload) {
		if (payload.data) {
			state.electionResults = payload.data;

			state.pageDetails = {
				total: payload.total,
				from: payload.from,
				to: payload.to,
				currentPage: payload.current_page,
				lastPage: payload.last_page,
				perPage: payload.per_page
			};
		} else {
			state.electionResults = payload;
		}
	}
};

const actions = {
	/**
	 * Get all election results.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} query
	 * @return {Object}
	 */
	all({ commit }, query) {
		return ElectionResultsService.all(query)
			.then((response) => {
				commit(SET_ELECTION_RESULTS, response);
				return Promise.resolve(response);
			})
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Store a new election result.
	 *
	 * @param  {Object} options
	 * @param  {Object} payload.data
	 * @return {Object}
	 */
	store({}, { data }) {
		return ElectionResultsService.store(data)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Get the specified election result.
	 *
	 * @param  {Object} options
	 * @param  {Number} id
	 * @return {Object}
	 */
	show({}, id) {
		return ElectionResultsService.show(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Update the specified election result.
	 *
	 * @param  {Object} options
	 * @param  {Object} payload
	 * @return {Object}
	 */
	update({}, { id, data }) {
		return ElectionResultsService.update(id, data)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Delete the specified election result.
	 *
	 * @param  {Object} options
	 * @param  {Number} id
	 * @return {Object}
	 */
	delete({}, id) {
		return ElectionResultsService.delete(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Confirm the specified election result.
	 *
	 * @param  {Object} options
	 * @param  {Number} id
	 * @return {Object}
	 */
	confirm({}, id) {
		return ElectionResultsService.confirm(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}
};

const namespaced = true;

export default {
	namespaced,
	state,
	actions,
	getters,
	mutations
}