import { axios } from './index';

export default {
	/**
	 * Get all elections.
	 *
	 * @param {Object} params
	 * @return {Object}
	 */
	all(params) {
		return axios.get('elections', {
				params: params
			})
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
	 * Store a new election.
	 *
	 * @param {Object} data
	 * @return {Object}
	 */
	store(data) {
		return axios.post('elections', data)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
     * Get the specified election.
     *
	 * @param {Number} id
	 * @return {Object}
     */
	show(id) {
	    return axios.get(`elections/${id}`)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
	 * Update the specified election.
	 *
	 * @param {Number} id
	 * @param {Object} data
	 * @return {Object}
	 */
	update(id, data) {
		return axios.put(`elections/${id}`, data)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
     * Delete the specified election.
     *
	 * @param {Number} id
	 * @return {Object}
     */
	delete(id) {
	    return axios.delete(`elections/${id}`)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
	 * Add a candidate to the specified election.
	 *
	 * @param {Number} id
	 * @param {Object} data
	 * @return {Object}
	 */
	addCandidate(id, data) {
		return axios.put(`elections/${id}/candidate`, data)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
     * Delete the specified election.
     *
	 * @param {Number} id
	 * @return {Object}
     */
	removeCandidate(id, data) {
	    return axios.delete(`elections/${id}/candidate`, { params: data })
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
	 * Activate the specified election.
	 *
	 * @param {Number} id
	 * @return {Object}
	 */
	activate(id) {
		return axios.put(`elections/${id}/activate`)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
	 * Deactivate the specified election.
	 *
	 * @param {Number} id
	 * @return {Object}
	 */
	deactivate(id) {
		return axios.put(`elections/${id}/deactivate`)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	loadNonCollatedCandidates(params) {
		return axios.get('elections/get_available_candidates', {
			params: params
		})
		.then(response => Promise.resolve(response.data))
		.catch(error => Promise.reject(error.response.data));
	}
}