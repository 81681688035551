<template>
	<nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
			<!--
			<router-link :to="{ name: 'dashboard' }" tag="li" class="nav-item" active-class="active" exact>
				<a class="nav-link">
					<i class="mdi mdi-view-dashboard-outline menu-icon"></i>

					<span class="menu-title">
						Dashboard
					</span>
				</a>
			</router-link>
			-->

            <li class="nav-item" :class="isModuleActive('hierarchies')">
				<a class="nav-link" :class="{collapsed: !isModuleActive('hierarchies').active}" data-toggle="collapse" href="#hierarchies-dropdown" :aria-expanded="isModuleActive('hierarchies').active ? 'true' : 'false'" aria-controls="ui-basic">
					<i class="mdi mdi-sitemap menu-icon"></i>
					<span class="menu-title">
						Hierarchies
					</span>

					<i class="menu-arrow"></i>
				</a>

				<div class="collapse" :class="{show: isModuleActive('hierarchies').active}" id="hierarchies-dropdown">
					<ul class="nav flex-column sub-menu">
						<router-link tag="li" :to="{ name: 'constituencies' }" class="nav-item" active-class="active" exact>
							<a class="nav-link">
								<i class="mdi mdi-umbrella"></i>
								Constituencies
							</a>
						</router-link>

						<router-link tag="li" :to="{ name: 'electoral_areas' }" class="nav-item" active-class="active" exact>
							<a class="nav-link">
								<i class="mdi mdi-access-point"></i>
								Electoral Areas
							</a>
						</router-link>

						<router-link tag="li" :to="{ name: 'branches' }" class="nav-item" active-class="active" exact>
							<a class="nav-link">
								<i class="mdi mdi-home"></i>
								Branches
							</a>
						</router-link>
					</ul>
				</div>
			</li>

            <!--
            <li class="nav-item" :class="isModuleActive('executives')">
				<a class="nav-link" data-toggle="collapse" href="#executives-dropdown" aria-expanded="false" aria-controls="ui-basic">
					<i class="mdi mdi-account-multiple menu-icon"></i>
					<span class="menu-title">
						Executives
					</span>

					<i class="menu-arrow"></i>
				</a>

				<div class="collapse" id="executives-dropdown">
					<ul class="nav flex-column sub-menu">
						<li class="nav-item">
							<router-link :to="{ name: 'executives', params: { level: 'all' }}" class="nav-link" active-class="active" exact>
								<i class="mdi mdi-account-supervisor"></i>
								All
							</router-link>
						</li>

						<li class="nav-item">
							<router-link :to="{ name: 'executives', params: { level: 'regional' }}" class="nav-link" active-class="active" exact>
								<i class="mdi mdi-account-supervisor"></i>
								Regional
							</router-link>
						</li>

						<li class="nav-item">
							<router-link :to="{ name: 'executives', params: { level: 'constituency' }}" class="nav-link" active-class="active" exact>
								<i class="mdi mdi-account-supervisor"></i>
								Constituency
							</router-link>
						</li>

						<li class="nav-item">
							<router-link :to="{ name: 'executives', params: { level: 'branch' }}" class="nav-link" active-class="active" exact>
								<i class="mdi mdi-account-supervisor"></i>
								Branch
							</router-link>
						</li>
					</ul>
				</div>
			</li>
			-->

			<li class="nav-item" :class="isModuleActive('elections')">
				<a class="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
					<i class="mdi mdi-bank menu-icon"></i>

					<span class="menu-title">
						Elections
					</span>

					<i class="menu-arrow"></i>
				</a>

				<div class="collapse" id="ui-basic">
					<ul class="nav flex-column sub-menu">
						<li class="nav-item">
							<router-link :to="{ name: 'elections' }" class="nav-link" active-class="active" exact>
								<i class="mdi mdi-office-building"></i>
								Headquarters
							</router-link>
						</li>

						<li class="nav-item">
							<router-link :to="{ name: 'candidates' }" class="nav-link" active-class="active" exact>
								<i class="mdi mdi-account-check"></i>
								Candidates
							</router-link>
						</li>

						<li class="nav-item">
							<router-link :to="{ name: 'data_collectors' }" class="nav-link" active-class="active" exact>
								<i class="mdi mdi-account-details"></i>
								Data Collectors
							</router-link>
						</li>
					</ul>
				</div>
			</li>

			<router-link :to="{ name: 'users' }" tag="li" class="nav-item" active-class="active" exact>
				<a class="nav-link">
					<i class="mdi mdi-account-group menu-icon"></i>

					<span class="menu-title">
						Users
					</span>
				</a>
			</router-link>
        </ul>
      </nav>
</template>

<script>
	export default {
		methods: {
			/**
			 * Determine whether the module with the given name has the currently active
			 * path
			 *
			 * @param {String} module Module
			 */
			isModuleActive(module) {
				return {
					active: this.$route.path.indexOf(`/${module}`) !== -1
				};
			}
		}
	}
</script>

<style scoped>
</style>