import BranchesService from '../../services/branches';
import { SET_BRANCHES } from '../mutation-types';

const state = {
	branches: [],
	pageDetails: {
		total: 0,
		from: 0,
		to: 0,
		perPage: 0,
		currentPage: 0,
		lastPage: 0
	}
};

const getters = {
	/**
	 * Get all branches from the state.
	 *
	 * @param  {Object} state
	 * @return {Object}
	 */
    getAll(state) {
        return state.branches;
	},

	/**
	 * Get pagination details for branches
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	getPageDetails(state) {
		return state.pageDetails;
	}
};

const mutations = {
    [SET_BRANCHES](state, payload) {
		if (payload.data) {
			state.branches = payload.data;

			state.pageDetails = {
				total: payload.total,
				from: payload.from,
				to: payload.to,
				currentPage: payload.current_page,
				lastPage: payload.last_page,
				perPage: payload.per_page
			};
		} else {
			state.branches = payload;
		}
    },
};

const actions = {
	/**
	 * Get branches with query params applied.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} query
	 * @return {Object}
	 */
    all({ commit }, query) {
        return BranchesService.all(query)
            .then((response) => {
                commit(SET_BRANCHES, response);
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Store a new branch.
	 *
	 * @param  {Object} options.commitmodule
	 * @param  {Object} options.actions
	 * @param  {Object} data Data for adding a branch
	 * @return {Object}
	 */
    store({ commit, actions }, { data }) {
        return BranchesService.store(data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Get the specified branch.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id Id of branch
	 * @return {Object}
	 */
    show({ commit, actions }, id) {
        return BranchesService.show(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Update the specified branch.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} id Id of branch
	 * @param  {Object} data Data of specified
	 * @return {Object}
	 */
    update({ commit, actions }, { id, data }) {
        return BranchesService.update(id, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Delete the specified branch.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id Id of branch
	 * @return {Object}
	 */
    delete({ commit, actions }, id) {
        return BranchesService.delete(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
};

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    getters,
    mutations
}