import HierarchiesService from '../../services/hierarchies';
import { SET_HIERARCHIES } from '../mutation-types';

const state = {
    hierarchies: []
};

const getters = {
	/**
	 * Get all hierarchies from the state
	 *
	 * @param  {Object} state
	 * @return {String}
	 */
    getAll(state) {
        return state.hierarchies;
    }
};

const mutations = {
    [SET_HIERARCHIES](state, payload) {
        state.hierarchies = payload;
    },
};

const actions = {
	/**
	 * Get all hierarchies from the server.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} query
	 * @return {Object}
	 */
    all({ commit }, query) {
        return HierarchiesService.all(query)
            .then((response) => {
                commit(SET_HIERARCHIES, response);
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Store a hierarchy
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} hierarchy
	 * @return {Object}
	 */
    store({ commit, actions }, hierarchy) {
        return HierarchiesService.store(hierarchy)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Get the specified hierarchy.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id Id of hierarchy
	 * @return {Object}
	 */
    show({ commit, actions }, id) {
        return HierarchiesService.show(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Update the specified hierarchy
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} hierarchy Update a given hierarchy
	 * @return {Object}
	 */
    update({ commit, actions }, hierarchy) {
        return HierarchiesService.update(hierarchy.id, hierarchy.data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Delete the specified hierarchy.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id Id of hierarchy
	 * @return {Object}
	 */
    delete({ commit, actions }, id) {
        return HierarchiesService.delete(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
};

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    getters,
    mutations
}