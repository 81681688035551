<template>
    <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
			<router-link :to="{ name: 'executives', params: { level: 'all' }}" class="navbar-brand brand-logo" active-class="" exact>
                <img src="../../assets/logo.png" alt="logo"/>
			</router-link>

			<router-link :to="{ name: 'dashboard' }" class="navbar-brand brand-logo-mini" active-class="" exact>
                <img src="../../assets/logo-mini.png" alt="logo"/>
			</router-link>
        </div>

        <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                <span class="mdi mdi-menu"></span>
            </button>

            <h3 class="mt-2">{{ pageTitle }}</h3>

            <ul class="navbar-nav navbar-nav-right">
                <li class="nav-item">
                    <a class="nav-link text-primary" href="#">
                        <i class="mdi mdi-account-settings"></i> Account
                    </a>
                </li>

                <li class="nav-item">
                    <a @click.prevent.stop="$emit('logout-user')" class="nav-link text-danger" href="#">
                        <i class="mdi mdi-logout"></i> Log Out
                    </a>
                </li>
            </ul>

            <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                <span class="mdi mdi-menu"></span>
            </button>
        </div>
    </nav>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';

	export default {
		computed: {
			...mapGetters([
				'pageTitle'
			])
		}
	}
</script>

<style scoped>
</style>