import { axios } from './index';

export default {
	/**
	 * Get all executives.
	 *
	 * @param {Object} params
	 * @return {Object}
	 */
    all(params) {
        return axios.get('executives', {
            params: params
        })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
	 * Add a new executive
	 *
	 * @param {Object} data
	 * @return {Object}
	 */
    store(data) {
        return axios.post('executives', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
     * Get the specified executive.
     *
	 * @param {Number} id
	 * @return {Object}
     */
    show(id) {
        return axios.get(`executives/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
	 * Update the specified executive.
	 *
	 * @param {Number} id
	 * @param {Object} data
	 * @return {Object}
	 */
    update(id, data) {
        data.append('_method', 'put');

        return axios.post(`executives/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

	/**
     * Delete the specified executive.
     *
	 * @param {Number} id
	 * @return {Object}
     */
    delete(id) {
        return axios.delete(`executives/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    }
}