import { axios } from './index';

export default {
	/**
	 * Get all election results.
	 *
	 * @param {Object} params
	 * @return {Object}
	 */
	all(params) {
		return axios.get('election_results', {
				params: params
			})
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
	 * Store a new election result.
	 *
	 * @param {Object} data
	 * @return {Object}
	 */
	store(data) {
		return axios.post('election_results', data)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
     * Get the specified election result.
     *
	 * @param {Number} id
	 * @return {Object}
     */
	show(id) {
	    return axios.get(`election_results/${id}`)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
	 * Update the specified election result.
	 *
	 * @param {Number} id
	 * @param {Object} data
	 * @return {Object}
	 */
	update(id, data) {
		return axios.put(`election_results/${id}`, data)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
     * Delete the specified election result.
     *
	 * @param {Number} id
	 * @return {Object}
     */
	delete(id) {
	    return axios.delete(`election_results/${id}`)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	},

	/**
     * Confirm the specified election result.
     *
	 * @param {Number} id
	 * @return {Object}
     */
	confirm(id) {
	    return axios.patch(`election_results/${id}/confirm`)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error.response.data));
	}
}