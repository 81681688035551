import ExecutiveTypesService from '../../services/executive_types';
import { SET_EXECUTIVE_TYPES } from '../mutation-types';

const state = {
    executive_types: []
};

const getters = {
	/**
	 * Get all executive types from the state.
	 *
	 * @param  {Object} state
	 * @return {String}
	 */
    getAll(state) {
        return state.executive_types;
    }
};

const mutations = {
    [SET_EXECUTIVE_TYPES](state, payload) {
        state.executive_types = payload;
    },
};

const actions = {
	/**
	 * Get all executive types.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} query
	 * @return {Object}
	 */
    all({ commit }, query) {
        return ExecutiveTypesService.all(query)
            .then((response) => {
                commit(SET_EXECUTIVE_TYPES, response);
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Store a new executive type.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} executiveType
	 * @return {Object}
	 */
    store({ commit, actions }, executiveType) {
        return ExecutiveTypesService.store(executiveType)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Get the specified executive type.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id Id of executive type
	 * @return {Object}
	 */
    show({ commit, actions }, id) {
        return ExecutiveTypesService.show(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Update the specified executive type.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} executiveType Update a given executive type
	 * @return {Object}
	 */
    update({ commit, actions }, executiveType) {
        return ExecutiveTypesService.update(executiveType.id, executiveType)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Delete the specified executive type.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id Id of executive type
	 * @return {Object}
	 */
    delete({ commit, actions }, id) {
        return ExecutiveTypesService.delete(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
};

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    getters,
    mutations
}