import Users from './users';
import Auth from './auth';
import Roles from './roles';
import Reports from './reports';
import Regions from './regions';
import Parties from './parties';
import Branches from './branches';
import Elections from './elections';
import Executives from './executives';
import Candidates from './candidates';
import Hierarchies from './hierarchies';
import Constituencies from './constituencies';
import ElectoralAreas from './electoral_areas';
import DataCollectors from './data_collectors';
import ExecutiveTypes from './executive_types';
import PollingSummaries from './polling_summaries';
import ElectionResults from './election_results';
import EducationalLevels from './educational_levels';

export default {
	Auth,
	Users,
	Roles,
	Regions,
	Parties,
	Reports,
	Branches,
	Elections,
	Candidates,
	Executives,
	Hierarchies,
	DataCollectors,
	Constituencies,
	ExecutiveTypes,
	ElectionResults,
	ElectoralAreas,
	PollingSummaries,
	EducationalLevels,
};
