import ReportsService from '../../services/reports';
import { SET_RESULT_SUMMARY_DATA } from '../mutation-types';

const state = {
    summary: {}
};

const getters = {
	/**
	 * Get election results summary
	 *
	 * @param  {Object} state
	 * @return {String}
	 */
    getSummaryData(state) {
        return state.summary;
    }
};

const mutations = {
    [SET_RESULT_SUMMARY_DATA](state, payload) {
        state.summary = payload;
    },
};

const actions = {
    /**
     * Load election results summary
     *
     * @param  {Object} options.commit
     * @param {Number} id Id of election
	 * @param  {Object} query Query params
	 * @return {Object}
     */
    loadSummary({ commit }, {id, query}) {
        return ReportsService.loadSummary(id, query)
            .then((response) => {
                commit(SET_RESULT_SUMMARY_DATA, response);
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    },

    getCollation({ commit }, {id, query}) {
        return ReportsService.getCollation(id, query)
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    }
};

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    getters,
    mutations
}
