import ElectionsService from '../../services/elections';
import { SET_ELECTIONS } from '../mutation-types';

const state = {
	elections: [],
	pageDetails: {
		total: 0,
		from: 0,
		to: 0,
		perPage: 0,
		currentPage: 0,
		lastPage: 0
	}
};

const getters = {
	/**
	 * Get all elections from the state.
	 *
	 * @param  {Object} state
	 * @return {String}
	 */
	getAll(state) {
		return state.elections;
	},

	/**
	 * Page details.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	getPageDetails(state) {
		return state.pageDetails;
	}
};

const mutations = {
	[SET_ELECTIONS](state, payload) {
		if (payload.data) {
			state.elections = payload.data;

			state.pageDetails = {
				total: payload.total,
				from: payload.from,
				to: payload.to,
				currentPage: payload.current_page,
				lastPage: payload.last_page,
				perPage: payload.per_page
			};
		} else {
			state.elections = payload;
		}
	}
};

const actions = {
	/**
	 * Get all elections.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} query
	 * @return {Object}
	 */
	all({ commit }, query) {
		return ElectionsService.all(query)
			.then((response) => {
				commit(SET_ELECTIONS, response);
				return Promise.resolve(response);
			})
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Store a new election.
	 *
	 * @param  {Object} options
	 * @param  {Object} payload.data
	 * @return {Object}
	 */
	store({}, { data }) {
		return ElectionsService.store(data)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Get the specified election.
	 *
	 * @param  {Object} options
	 * @param  {Number} id
	 * @return {Object}
	 */
	show({}, id) {
		return ElectionsService.show(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Update the specified election.
	 *
	 * @param  {Object} options
	 * @param  {Object} payload
	 * @return {Object}
	 */
	update({}, { id, data }) {
		return ElectionsService.update(id, data)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Delete the specified election.
	 *
	 * @param  {Object} options
	 * @param  {Number} id
	 * @return {Object}
	 */
	delete({}, id) {
		return ElectionsService.delete(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Add a candidate to the specified election.
	 *
	 * @param  {Object} options
	 * @param  {Object} payload
	 * @return {Object}
	 */
	addCandidate({}, payload) {
		return ElectionsService.addCandidate(payload.election_id, payload.candidate)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Remove a candidate from the specified election.
	 *
	 * @param  {Object} options
	 * @param  {Number} id
	 * @return {Object}
	 */
	removeCandidate({}, payload) {
		return ElectionsService.removeCandidate(payload.election_id, payload.candidate)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Activate the specified election.
	 *
	 * @param  {Object} options
	 * @param  {Number} id
	 * @return {Object}
	 */
	activate({}, id) {
		return ElectionsService.activate(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Deactivate the specified election.
	 *
	 * @param  {Object} options
	 * @param  {Number} id
	 * @return {Object}
	 */
	deactivate({}, id) {
		return ElectionsService.deactivate(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Get all candidates that don't have results collated yet.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} query
	 * @return {Object}
	 */
	loadNonCollatedCandidates({ commit }, query) {
		return ElectionsService.loadNonCollatedCandidates(query)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}
};

const namespaced = true;

export default {
	namespaced,
	state,
	actions,
	getters,
	mutations
}