import { axios } from './index';

export default {
    /**
     * Get all rejected ballots.
     *
     * @param {Object} params
     * @return {Object}
     */
    all(params) {
        return axios.get('polling_summaries', {
            params: params
        })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Record a new set of rejected ballots
     *
     * @param {Object} data
     * @return {Object}
     */
    store(data) {
        return axios.post('polling_summaries', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Get the specified rejected ballot recording.
     *
     * @param {Number} id
     * @return {Object}
     */
    show(id) {
        return axios.get(`polling_summaries/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Update the specified rejected ballot
     *
     * @param {Number} id
     * @param {Object} data
     * @return {Object}
     */
    update(id, data) {
        return axios.put(`polling_summaries/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Delete the specified rejected ballot.
     *
     * @param {Number} id
     * @return {Object}
     */
    delete(id) {
        return axios.delete(`polling_summaries/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    }
}