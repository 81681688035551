import PollingSummariesService from '../../services/polling_summaries';
import { SET_POLLING_SUMMARIES } from '../mutation-types';

const state = {
    pollingSummaries: [],
    pageDetails: {
        total: 0,
        from: 0,
        to: 0,
        perPage: 0,
        currentPage: 0,
        lastPage: 0
    }
};

const getters = {
    /**
     * Get all RejectedBallots from the state.
     *
     * @param  {Object} state
     * @return {Object}
     */
    getAll(state) {
        return state.pollingSummaries;
    },

    /**
     * Get pagination details for RejectedBallots
     *
     * @param {Object} state
     * @return {Object}
     */
    getPageDetails(state) {
        return state.pageDetails;
    }
};

const mutations = {
    [SET_POLLING_SUMMARIES](state, payload) {
        if (payload.data) {
            state.pollingSummaries = payload.data;

            state.pageDetails = {
                total: payload.total,
                from: payload.from,
                to: payload.to,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
                perPage: payload.per_page
            };
        } else {
            state.pollingSummaries = payload;
        }
    },
};

const actions = {
    /**
     * Get Polling summaries with query params applied.
     *
     * @param  {Object} options.commit
     * @param  {Object} query
     * @return {Object}
     */
    all({ commit }, query) {
        return PollingSummariesService.all(query)
            .then((response) => {
                commit(SET_POLLING_SUMMARIES, response);
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    },

    /**
     * Store a new polling summary
     *
     * @param  {Object} options.commitmodule
     * @param  {Object} options.actions
     * @param  {Object} data Data for adding a branch
     * @return {Object}
     */
    store({ commit, actions }, { data }) {
        return PollingSummariesService.store(data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

    /**
     * Get the specified summary
     *
     * @param  {Object} options.commit
     * @param  {Object} options.actions
     * @param  {Number} id Id of branch
     * @return {Object}
     */
    show({ commit, actions }, id) {
        return PollingSummariesService.show(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

    /**
     * Update the specified sumary.
     *
     * @param  {Object} options.commit
     * @param  {Object} options.actions
     * @param  {Object} id Id of branch
     * @param  {Object} data Data of specified
     * @return {Object}
     */
    update({ commit, actions }, { id, data }) {
        return PollingSummariesService.update(id, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

    /**
     * Delete the specified summary.
     *
     * @param  {Object} options.commit
     * @param  {Object} options.actions
     * @param  {Number} id Id of branch
     * @return {Object}
     */
    delete({ commit, actions }, id) {
        return PollingSummariesService.delete(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
};

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    getters,
    mutations
}