import { axios } from './index';

export default {
    /**
     * Load election results summary
     *
     * @param {Number} id Id of election to load report for
     * @param {Object} params Query params
     */
    loadSummary(id, params) {
        return axios.get(`election_reports/summary/${id}`, {
                params
            })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getCollation(id, params) {
        return axios.get(`election_reports/collation/${id}`, {
            params
        })
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
    }
}