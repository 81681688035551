import CandidatesService from '../../services/candidates';
import { SET_CANDIDATES } from '../mutation-types';

const state = {
	candidates: [],
	pageDetails: {
		total: 0,
		from: 0,
		to: 0,
		perPage: 0,
		currentPage: 0,
		lastPage: 0
	}
};

const getters = {
	/**
	 * Get all candidates from the state.
	 *
	 * @param  {Object} state
	 * @return {String}
	 */
	getAll(state) {
		return state.candidates;
	},

	/**
	 * Page details.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	getPageDetails(state) {
		return state.pageDetails;
	}
};

const mutations = {
	[SET_CANDIDATES](state, payload) {
		if (payload.data) {
			state.candidates = payload.data;

			state.pageDetails = {
				total: payload.total,
				from: payload.from,
				to: payload.to,
				currentPage: payload.current_page,
				lastPage: payload.last_page,
				perPage: payload.per_page
			};
		} else {
			state.candidates = payload;
		}
	}
};

const actions = {
	/**
	 * Get all candidates.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} query
	 * @return {Object}
	 */
	all({ commit }, query) {
		return CandidatesService.all(query)
			.then((response) => {
				commit(SET_CANDIDATES, response);
				return Promise.resolve(response);
			})
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Store a new candidate.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} payload
	 * @return {Object}
	 */
	store({ commit, actions }, { data }) {
		return CandidatesService.store(data)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Get the specified candidate.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id
	 * @return {Object}
	 */
	show({ commit, actions }, id) {
		return CandidatesService.show(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Update the specified candidate.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} payload
	 * @return {Object}
	 */
	update({ commit, actions }, { id, data }) {
		return CandidatesService.update(id, data)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	/**
	 * Delete the specified candidate.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id
	 * @return {Object}
	 */
	delete({ commit, actions }, id) {
		return CandidatesService.delete(id)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	},

	loadElectionCandidates({ commit }, query) {
		return CandidatesService.all(query)
			.then(response => Promise.resolve(response))
			.catch(error => Promise.reject(error));
	}
};

const namespaced = true;

export default {
	namespaced,
	state,
	actions,
	getters,
	mutations
}