import PartiesService from '../../services/parties';
import { SET_PARTIES } from '../mutation-types';

const state = {
    parties: []
};

const getters = {
    /**
     * Get all parties from the state.
     *
     * @param  {Object} state
     * @return {String}
     */
    getAll(state) {
        return state.parties;
    }
};

const mutations = {
    [SET_PARTIES](state, payload) {
        state.parties = payload;
    },
};

const actions = {
    /**
     * Get all parties.
     *
     * @param  {Object} options.commit
     * @param  {Object} query
     * @return {Object}
     */
    all({ commit }, query) {
        return PartiesService.all(query)
            .then((response) => {
                commit(SET_PARTIES, response);
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    },
};

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    getters,
    mutations
};