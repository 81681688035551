import ElectoralAreaService from '../../services/electoral_areas';
import { SET_ELECTORAL_AREAS } from '../mutation-types';

const state = {
    electoral_areas: [],
    pageDetails: {
        total: 0,
        from: 0,
        to: 0,
        perPage: 0,
        currentPage: 0,
        lastPage: 0
    }
};

const getters = {
    /**
     * Get all electoral areas from the state.
     *
     * @param  {Object} state
     * @return {Object}
     */
    getAll(state) {
        return state.electoral_areas;
    },

    /**
     * Get pagination details for electoral areas
     *
     * @param {Object} state
     * @return {Object}
     */
    getPageDetails(state) {
        return state.pageDetails;
    }
};

const mutations = {
    [SET_ELECTORAL_AREAS](state, payload) {
        if (payload.data) {
            state.electoral_areas = payload.data;

            state.pageDetails = {
                total: payload.total,
                from: payload.from,
                to: payload.to,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
                perPage: payload.per_page
            };
        } else {
            state.electoral_areas = payload;
        }
    },
};

const actions = {
    /**
     * Get electoral areas with query params applied.
     *
     * @param  {Object} options.commit
     * @param  {Object} query
     * @return {Object}
     */
    all({ commit }, query) {
        return ElectoralAreaService.all(query)
            .then((response) => {
                commit(SET_ELECTORAL_AREAS, response);
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    },

    /**
     * Store a new electoral area.
     *
     * @param  {Object} options.commitmodule
     * @param  {Object} options.actions
     * @param  {Object} data Data for adding a electoral area
     * @return {Object}
     */
    store({ commit, actions }, { data }) {
        return ElectoralAreaService.store(data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

    /**
     * Get the specified electoral area.
     *
     * @param  {Object} options.commit
     * @param  {Object} options.actions
     * @param  {Number} id Id of electoral area
     * @return {Object}
     */
    show({ commit, actions }, id) {
        return ElectoralAreaService.show(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

    /**
     * Update the specified electoral area.
     *
     * @param  {Object} options.commit
     * @param  {Object} options.actions
     * @param  {Object} id Id of electoral area
     * @param  {Object} data Data of specified
     * @return {Object}
     */
    update({ commit, actions }, { id, data }) {
        return ElectoralAreaService.update(id, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

    /**
     * Delete the specified electoral area.
     *
     * @param  {Object} options.commit
     * @param  {Object} options.actions
     * @param  {Number} id Id of electoral area
     * @return {Object}
     */
    delete({ commit, actions }, id) {
        return ElectoralAreaService.delete(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
};

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    getters,
    mutations
}