import AppLoader from '../components/partials/Loader';

const Dashboard = () => ({
    loading: AppLoader,
    component: import('../views/Dashboard'),
});

const Login = () => ({
    loading: AppLoader,
    component: import('../views/login/Index'),
});

const Elections = () => ({
    loading: AppLoader,
    component: import('../views/elections/headquarters/Index'),
});

const ViewElection = () => ({
    loading: AppLoader,
    component: import('../views/elections/headquarters/view/Index'),
});

const Candidates = () => ({
    loading: AppLoader,
    component: import('../views/elections/candidates/Index'),
});

const DataCollectors = () => ({
    loading: AppLoader,
    component: import('../views/elections/data_collectors/Index'),
});

const Executives = () => ({
    loading: AppLoader,
    component: import('../views/executives/Index')
});

const Constituencies = () => ({
    loading: AppLoader,
    component: import('../views/hierarchies/constituencies/Index')
});

const ElectoralAreas = () => ({
    loading: AppLoader,
    component: import('../views/hierarchies/electoral_areas/Index')
});

const Branches = () => ({
    loading: AppLoader,
    component: import('../views/hierarchies/branches/Index')
});

const ElectionReports = () => ({
    loading: AppLoader,
    component: import('../views/elections/reports/Index')
});

const Users = () => ({
    loading: AppLoader,
    component: import('../views/users/Index')
});

export default [
    {
		path: '/',
		component: Dashboard,
		name: 'dashboard'
    },

    {
		path: '/login',
		component: Login,
		name: 'login'
    },

    {
		path: '/elections',
		component: Elections,
		name: 'elections'
    },

    {
        path: '/elections/candidates',
        component: Candidates,
        name: 'candidates'
    },

    {
        path: '/elections/data_collectors',
        component: DataCollectors,
        name: 'data_collectors'
    },

    {
        path: '/elections/reports/:election_id',
        component: ElectionReports,
        name: 'election_reports',
        props: true
    },

    {
		path: '/elections/:id',
		component: ViewElection,
        name: 'view_election',
        props: true
    },

    {
		path: '/executives/:level',
		component: Executives,
        name: 'executives',
        props: true
    },

    {
        path: '/hierarchies/constituencies',
        component: Constituencies,
        name: 'constituencies',
    },

    {
        path: '/hierarchies/electoral_areas',
        component: ElectoralAreas,
        name: 'electoral_areas',
    },

    {
        path: '/hierarchies/branches',
        component: Branches,
        name: 'branches'
    },

    {
        path: '/users',
        component: Users,
        name: 'users'
    }
];
