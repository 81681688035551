<template>
	<div class="container-scroller">
        <div v-if="isUserLoggedIn">
            <app-header @logout-user="logoutUser"></app-header>

            <div class="container-fluid page-body-wrapper">
                <app-sidebar></app-sidebar>

                <div class="main-panel">
                    <div class="content-wrapper">
                        <transition name="fade" mode="out-in">
                            <router-view></router-view>
                        </transition>
                    </div>

                    <app-footer></app-footer>
                </div>
            </div>
        </div>

		<div v-else class="container-fluid">
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</div>

		<app-loader v-show="showAppLoader"></app-loader>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import Layouts from '../components/layouts';

	export default {
		components: {
			...Layouts
		},

		computed: {
			...mapGetters({
                isUserLoggedIn: 'Auth/check',
				showAppLoader: 'showLoaderStatus',
			})
		},

		methods: {
			...mapActions({
				logout: 'Auth/logout',
			}),

			logoutUser() {
				this.logout();
				this.$router.push({ name: 'login' });
			}
		}
	}
</script>

<style>
	.fade-enter-active, .fade-leave-active {
	  transition: opacity .3s
    }

	.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
	  opacity: 0
	}

	table.dataTable.table-striped {
		border-collapse: collapse !important;
	}
</style>