import { axios } from './index';

export default {
    /**
     * Get all parties.
     *
     * @param {Object} params
     * @return {Object}
     */
    all(params) {
        return axios.get('parties', {
            params: params
        })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    }
};