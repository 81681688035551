import Modal from './Modal';
import AppLoader from './Loader';
import ErrorLabel from './ErrorLabel';
import AppPagination from './Pagination';
import Toolbar from './Toolbar';

export default {
	Modal,
	Toolbar,
	AppLoader,
	ErrorLabel,
	AppPagination
}