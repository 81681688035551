import EducationalLevelsService from '../../services/educational_levels';
import { SET_EDUCATIONAL_LEVELS } from '../mutation-types';

const state = {
    educational_levels: []
};

const getters = {
	/**
	 * Get all education levels from the state.
	 *
	 * @param  {Object} state
	 * @return {String}
	 */
    getAll(state) {
        return state.educational_levels;
    }
};

const mutations = {
    [SET_EDUCATIONAL_LEVELS](state, payload) {
        state.educational_levels = payload;
    },
};

const actions = {
	/**
	 * Get all educational levels from the server.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} query
	 * @return {Object}
	 */
    all({ commit }, query) {
        return EducationalLevelsService.all(query)
            .then((response) => {
                commit(SET_EDUCATIONAL_LEVELS, response);
                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Store a new educational level.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} educationalLevel
	 * @return {Object}
	 */
    store({ commit, actions }, educationalLevel) {
        return EducationalLevelsService.store(educationalLevel)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Get the specified educational level.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id Id of educational level
	 * @return {Object}
	 */
    show({ commit, actions }, id) {
        return EducationalLevelsService.show(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Update the specified educational level
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Object} executiveType Update a given educational level
	 * @return {Object}
	 */
    update({ commit, actions }, educationalLevel) {
        return EducationalLevelsService.update(educationalLevel.id, educationalLevel)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },

	/**
	 * Delete the specified educational level.
	 *
	 * @param  {Object} options.commit
	 * @param  {Object} options.actions
	 * @param  {Number} id Id of educational level
	 * @return {Object}
	 */
    delete({ commit, actions }, id) {
        return EducationalLevelsService.delete(id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
};

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    getters,
    mutations
}